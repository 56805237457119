<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>DollarCircle</title>
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10.89 9.1C9.11 8.51 8.25 8.14 8.25 7.2C8.25 6.18 9.36 5.81 10.06 5.81C11.37 5.81 11.85 6.8 11.96 7.15L13.54 6.48C13.39 6.04 12.72 4.57 10.88 4.25V3H9.13V4.26C6.53 4.82 6.51 7.11 6.51 7.22C6.51 9.49 8.76 10.13 9.86 10.53C11.44 11.09 12.14 11.6 12.14 12.56C12.14 13.69 11.09 14.17 10.16 14.17C8.34 14.17 7.82 12.3 7.76 12.08L6.1 12.75C6.73 14.94 8.38 15.53 9.12 15.71V17H10.87V15.76C11.39 15.67 13.89 15.17 13.89 12.54C13.9 11.15 13.29 9.93 10.89 9.1Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ic-dollar-circle',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
