<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 72 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="36"
      cy="36.5"
      r="36"
      fill="#F9FAFB"
    />
    <circle
      cx="35.8076"
      cy="36"
      r="29.5"
      fill="#E5E7EB"
    />
    <path
      d="M29.0884 33.5L27.8384 30.75L25.0884 29.5L27.8384 28.25L29.0884 25.5L30.3384 28.25L33.0884 29.5L30.3384 30.75L29.0884 33.5Z"
      fill="#6D7883"
    />
    <path
      d="M29.0884 39.5L30.3384 42.25L33.0884 43.5L30.3384 44.75L29.0884 47.5L27.8384 44.75L25.0884 43.5L27.8384 42.25L29.0884 39.5Z"
      fill="#6D7883"
    />
    <path
      d="M36.5884 34L39.0884 28.5L41.5884 34L47.0884 36.5L41.5884 39L39.0884 44.5L36.5884 39L31.0884 36.5L36.5884 34ZM38.0984 37.49L39.0884 39.67L40.0784 37.49L42.2584 36.5L40.0784 35.51L39.0884 33.33L38.0984 35.51L35.9184 36.5L38.0984 37.49Z"
      fill="#6D7883"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcStarCircleDisabled',
  props: {
    size: {
      type: [String, Number],
      default: 72,
    },
  },
}
</script>
