<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 26 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9706 9.63701L11.0662 15.5414C8.83883 14.4736 6.08819 14.8625 4.24264 16.7081C1.90212 19.0486 1.90212 22.8528 4.24264 25.1934C6.58316 27.5339 10.3874 27.5339 12.7279 25.1934C14.5735 23.3478 14.9624 20.5972 13.8946 18.3698L14.1421 18.1223H16.9706V15.2939H19.799L19.7707 9.60873L16.9706 9.63701ZM10.6066 23.072C9.43988 24.2388 7.53069 24.2388 6.36396 23.072C5.19723 21.9053 5.19723 19.9961 6.36396 18.8294C7.53069 17.6627 9.43988 17.6627 10.6066 18.8294C11.7733 19.9961 11.7733 21.9053 10.6066 23.072Z"
      fill="currentColor"
    />
    <path
      d="M21.1445 17.1924L21.5749 20.37C21.6336 20.8029 21.9664 21.1476 22.3969 21.2214L25.1445 21.6924L22.3969 22.1634C21.9664 22.2372 21.6336 22.582 21.5749 23.0148L21.1445 26.1924L20.7141 23.0148C20.6555 22.582 20.3227 22.2372 19.8921 22.1634L17.1445 21.6924L19.8921 21.2214C20.3227 21.1476 20.6555 20.8029 20.7141 20.37L21.1445 17.1924Z"
      fill="currentColor"
    />
    <path
      d="M12.9805 0.564003L13.4647 3.74182C13.5306 4.17438 13.8701 4.51386 14.3026 4.57977L17.4805 5.064L14.3026 5.54823C13.8701 5.61414 13.5306 5.95362 13.4647 6.38618L12.9805 9.564L12.4962 6.38618C12.4303 5.95362 12.0908 5.61414 11.6583 5.54823L8.48047 5.064L11.6583 4.57977C12.0908 4.51386 12.4303 4.17438 12.4962 3.74182L12.9805 0.564003Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcSparkleKey',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>
