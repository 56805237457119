<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    month: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      // Default chart options
      chartOptions: {
        legend: {
          display: false, // Hide the labels
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: -10,
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false, // Keep horizontal lines disabled
              },
              ticks: {
                beginAtZero: true,
                display: false, // Hide the tick labels
                max: this.getMaxYValue(), // Set max value dynamically
              },
            },
            {
              position: 'right', // Add this yAxis at the right
              gridLines: {
                display: false, // Enable grid lines
                borderColor: '#E0E0E0',
                borderDash: [10, 5], // Creates dotted lines
              },
              ticks: {
                display: false, // Hide the tick labels
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true, // Enable vertical lines
                drawBorder: true,
                borderColor: '#E0E0E0',
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [10, 5], // Creates dotted lines
              },
              ticks: {
                display: false, // Show the tick labels
                padding: 10,
                callback: function (value, index, values) {
                  const indices = [
                    0,
                    Math.floor(values.length / 4),
                    Math.floor(values.length / 2),
                    Math.floor((values.length * 3) / 4),
                    values.length - 1,
                  ]

                  return indices.includes(index) ? value : null
                },
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0.4, // Smoother lines
          },
          point: {
            radius: 0, // Removes the dots
          },
        },
        tooltips: {
          enabled: true,
          mode: 'nearest',
          intersect: false,
          callbacks: {
            title: () => null,
            label: (tooltipItem, data) => {
              if (tooltipItem.datasetIndex === 0) {
                var dataset = data.datasets[tooltipItem.datasetIndex]
                var value = dataset.data[tooltipItem.index] || ''
                const monthNames = [
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ]
                const monthName = monthNames[this.month - 1]
                if (value === '' || value === null) {
                  return `No data - ${monthName} ${tooltipItem.index + 1}`
                }
                if (this.currency) {
                  return `${value.toFixed(0)} ${this.currency} - ${monthName} ${tooltipItem.index + 1}`
                }
                return `${value.toFixed(0)} - ${monthName} ${tooltipItem.index + 1}`
              }
              return null
            },
            labelColor: (tooltipItem, chart) => {
              // Only show tooltip on the line we care about
              if (tooltipItem.datasetIndex === 0) {
                return {
                  borderColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor,
                  backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor,
                }
              }
            },
          },
          filter: function (tooltipItem) {
            return tooltipItem.datasetIndex === 0
          },
        },
      },
    }
  },

  methods: {
    getMaxYValue() {
      let max = 1 // > 0 default value for visuals on flatlines
      this.chartData.datasets.forEach(dataset => {
        if (dataset.label !== 'Area Above Line') {
          const datasetMax = Math.max(...dataset.data)
          if (datasetMax > max) {
            max = datasetMax
          }
        }
      })
      return max * 1.3 // Add 30%
    },
    getAreaAboveLineData() {
      const maxYValue = this.getMaxYValue()
      let actual = []
      this.chartData.datasets.forEach(dataset => {
        if (dataset.label === 'Actual') {
          actual = dataset.data
        }
      })
      return actual.map(value => (value === null ? null : maxYValue))
    },
  },
  mounted() {
    // Adjust the order of datasets
    const datasets = this.chartData.datasets
    this.chartData.datasets = [datasets[0], datasets[2], datasets[1]]

    this.chartData.datasets.forEach(dataset => {
      if (dataset.label === 'Area Above Line') {
        dataset.data = this.getAreaAboveLineData()
      }
      // Set the tension for smoother lines
      dataset.tension = 0.4
    })
    this.renderChart(this.chartData, this.options || this.chartOptions)
  },
}
</script>
