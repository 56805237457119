<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Currency Exchange</title>
    <path
      d="M16.1868 13.8C13.8135 13.0133 12.6668 12.52 12.6668 11.2666C12.6668 9.90665 14.1468 9.41331 15.0802 9.41331C16.8268 9.41331 17.4668 10.7333 17.6135 11.2L19.7202 10.3066C19.5202 9.70665 18.6268 7.74665 16.3335 7.31998V5.66665H13.6668V7.34665C10.3602 8.09331 10.3468 11.16 10.3468 11.2933C10.3468 14.32 13.3468 15.1733 14.8135 15.7066C16.9202 16.4533 17.8535 17.1333 17.8535 18.4133C17.8535 19.92 16.4535 20.56 15.2135 20.56C12.7868 20.56 12.0935 18.0666 12.0135 17.7733L9.80016 18.6666C10.6402 21.5866 12.8402 22.3733 13.6668 22.6133V24.3333H16.3335V22.68C16.8668 22.56 20.2002 21.8933 20.2002 18.3866C20.2002 16.5333 19.3868 14.9066 16.1868 13.8ZM3.00016 27H0.333496V19H8.3335V21.6666H5.02683C7.1735 24.88 10.8402 27 15.0002 27C21.6268 27 27.0002 21.6266 27.0002 15H29.6668C29.6668 23.1066 23.1068 29.6666 15.0002 29.6666C10.0402 29.6666 5.6535 27.2 3.00016 23.44V27ZM0.333496 15C0.333496 6.89331 6.8935 0.333313 15.0002 0.333313C19.9602 0.333313 24.3468 2.79998 27.0002 6.55998V2.99998H29.6668V11H21.6668V8.33331H24.9735C22.8268 5.11998 19.1602 2.99998 15.0002 2.99998C8.3735 2.99998 3.00016 8.37331 3.00016 15H0.333496Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-currency-exchange',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
