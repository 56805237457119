<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Average CPC</title>
    <path
      d="M9.28317 7.72007C9.28317 8.3766 9.91207 8.63502 11.2137 9.0471C12.9688 9.62681 13.4149 10.4789 13.4149 11.4497C13.4149 13.2866 11.5867 13.6358 11.2942 13.6987V14.5648H9.83163V13.6638C9.37824 13.5381 8.17164 13.126 7.71094 11.5964L8.92485 11.1284C8.96873 11.2821 9.34899 12.5882 10.6799 12.5882C11.36 12.5882 12.1278 12.2529 12.1278 11.4637C12.1278 10.7932 11.6159 10.437 10.4605 10.0459C9.65612 9.76649 8.01076 9.31949 8.01076 7.73403C8.01076 7.66419 8.01807 6.05778 9.83163 5.66665V4.78662H11.2942V5.65269C12.552 5.87619 13.0419 6.90289 13.1516 7.21719L11.9962 7.68514C11.9158 7.44069 11.5647 6.74924 10.6068 6.74924C10.0949 6.74924 9.28317 7.00766 9.28317 7.72007Z"
      fill="currentColor"
    />
    <path
      d="M22.6844 19.2464L20.0009 19.5552L21.7791 22.5725C21.9029 22.7816 21.8189 23.0417 21.6048 23.1611L20.4188 23.8053C20.1978 23.9255 19.9255 23.8485 19.8025 23.6461L18.113 20.781L16.1607 23.1175C15.9005 23.4289 15.4021 23.2693 15.3602 22.9051L14.204 12.8582C14.1599 12.4748 14.6423 12.2298 14.9353 12.4695L22.9168 18.4819C23.237 18.7098 23.0766 19.2012 22.6844 19.2464Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3356 16.9854C12.4771 17.3159 11.5444 17.4971 10.5694 17.4971C6.31588 17.4971 2.86774 14.049 2.86774 9.79545C2.86774 5.54195 6.31588 2.09381 10.5694 2.09381C14.8229 2.09381 18.271 5.54195 18.271 9.79545C18.271 10.7722 18.0892 11.7065 17.7575 12.5663L19.3689 13.7801C19.9198 12.5653 20.2266 11.2162 20.2266 9.79545C20.2266 4.46189 15.9029 0.138184 10.5694 0.138184C5.23582 0.138184 0.912109 4.46189 0.912109 9.79545C0.912109 15.129 5.23582 19.4527 10.5694 19.4527C11.6151 19.4527 12.622 19.2865 13.565 18.9791L13.3356 16.9854Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-average-cpc',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
