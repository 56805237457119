<template>
  <div class="p-6 border rounded-md">
    <div class="w-full">
      <div class="flex w-full text-normal font-medium">
        <slot name="header"> </slot>
      </div>
      <div class="pt-6 text-bb-text-secondary text-sm font-medium">
        <slot name="contents"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentCard',
}
</script>

<style lang="scss" scoped>
.disabled {
  color: $bb-cool-grey !important;
}
</style>
