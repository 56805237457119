<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="18.5"
      cy="18"
      r="18"
      fill="#6366FA"
      fill-opacity="0.1"
    />
    <circle
      cx="18.4062"
      cy="17.75"
      r="14.75"
      fill="#6366FA"
      fill-opacity="0.1"
    />
    <path
      d="M21.2992 12.7735L17.5421 16.5306C16.1248 15.8511 14.3745 16.0986 13.2002 17.273C11.7108 18.7623 11.7108 21.183 13.2002 22.6723C14.6895 24.1617 17.1102 24.1617 18.5995 22.6723C19.7739 21.498 20.0214 19.7477 19.3419 18.3303L19.4994 18.1729L21.2992 18.1729L21.2992 16.3731L23.099 16.3731L23.081 12.7555L21.2992 12.7735ZM17.2497 21.3225C16.5073 22.0649 15.2924 22.0649 14.55 21.3225C13.8076 20.5801 13.8076 19.3652 14.55 18.6228C15.2924 17.8804 16.5073 17.8804 17.2497 18.6228C17.9921 19.3652 17.9921 20.5801 17.2497 21.3225Z"
      fill="#6366FA"
    />
    <path
      d="M23.9541 17.5811L24.2279 19.603C24.2652 19.8785 24.477 20.0978 24.751 20.1448L26.4993 20.4445L24.751 20.7442C24.477 20.7912 24.2652 21.0105 24.2279 21.286L23.9541 23.3079L23.6802 21.286C23.6429 21.0105 23.4311 20.7912 23.1571 20.7442L21.4088 20.4445L23.1571 20.1448C23.4311 20.0978 23.6429 19.8785 23.6802 19.603L23.9541 17.5811Z"
      fill="#6366FA"
    />
    <path
      d="M18.7607 7.00024L19.0688 9.02236C19.1107 9.29761 19.3267 9.51362 19.602 9.55556L21.6241 9.86369L19.602 10.1718C19.3267 10.2138 19.1107 10.4298 19.0688 10.705L18.7607 12.7271L18.4525 10.705C18.4106 10.4298 18.1946 10.2138 17.9193 10.1718L15.8972 9.86369L17.9193 9.55556C18.1946 9.51362 18.4106 9.29761 18.4525 9.02236L18.7607 7.00024Z"
      fill="#6366FA"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcKeysStar',
  props: {
    size: {
      type: [String, Number],
      default: 31,
    },
  },
}
</script>
