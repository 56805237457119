<template>
  <div v-if="!loading">
    <div
      class="bg-purple-0 rounded-2xl p-6 flex flex-row w-full gap-4"
      :class="{
        'justify-center': !siteWelcomeMessage,
      }"
    >
      <div class="min-w-12">
        <img
          class="min-w-full"
          src="/content/images/brightbid/welcome-bot.svg"
          alt="welcome-robot"
        />
      </div>
      <div
        class="max-w-4xl"
        v-if="siteWelcomeMessage"
      >
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-2">
            <p class="h4">Hi, {{ user.given_name }}</p>
            <div class="animate-wave my-auto text-xl">👋🏼</div>
          </div>
          <p class="h5">Here’s a summary of last 30 days compared to previous 30 days performance.</p>
          <div class="relative">
            <p
              class="invisible pre-render"
              v-html="siteWelcomeMessage?.response"
            ></p>
            <p
              class="typewriter absolute top-0 left-0"
              v-html="displayedHighlightedResponse"
            ></p>
          </div>
            <div class="flex flex-wrap gap-2 mt-2">
                <div
                    v-for="(value, key) in siteWelcomeMessage.metrics"
                    :key="key"
                    v-if="value !== undefined"
                    class="rounded-full px-3 py-1 capitalize flex flex-row gap-2 bg-white"
                >
                    <component
                        :is="getIconForMetric(key, value.variation_percentage)"
                        :class="getIconColor(key, value.variation_percentage)"
                    ></component>
                    {{ key }}:
                    <span
                        :class="getVarianceColors(key, value.variation_percentage).colors"
                        class="rounded-full font-bold px-2"
                    >
                {{ getVarianceColors(key, value.variation_percentage).formattedValue }}
              </span>
                </div>
            </div>
        </div>
          <div class="flex mt-8">
              <p class="h5">Top campaign picks:</p>
          </div>
        <div
          class="mt-4"
          v-for="(motivation, campaign) in siteWelcomeMessage.best_campaigns"
          :key="campaign"
        >
          <div class="flex flex-row gap-2">
            <IcCreationOutline
              class="text-bb-brand-purple my-auto"
              :size="16"
            ></IcCreationOutline>
            <p class="font-medium"><span class="text-bb-brand-purple font-bold">{{ campaign }}</span>: {{ motivation}}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-2">
            <p class="h4">Hi, {{ user.given_name }}</p>
            <div class="animate-wave my-auto text-xl">👋🏼</div>
          </div>
          <p class="h5">It appears there's no data yet!</p>
          <p>Insights will be shown here as soon as they become available.</p>
          <!-- <div class="relative">
            <p
              class="invisible pre-render"
              v-html="highlightedResponse"
            ></p>
            <p
              class="typewriter absolute top-0 left-0"
              v-html="displayedHighlightedResponse"
            ></p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <brightbid-loader size="120" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import IcTrendingUp from 'vue-material-design-icons/TrendingUp'
import IcTrendingDown from 'vue-material-design-icons/TrendingDown'
import IcTrendingNeutral from 'vue-material-design-icons/TrendingNeutral'
import IcCreationOutline from 'vue-material-design-icons/CreationOutline'
import BrightbidLoader from '@/components/loader/BrightbidLoader'

export default {
  name: 'WelcomeWidget',
  data() {
    return {
      loading: false,
      index: 0,
      fullHighlightedResponse: '',
      displayedHighlightedResponse: '',
    }
  },
  components: {
    IcTrendingUp,
    IcTrendingDown,
    IcTrendingNeutral,
    IcCreationOutline,
    BrightbidLoader,
  },
  props: {
    siteId: Number,
    user: Object,
  },
  watch: {
    async siteId() {
      if (!this.siteId) return
      this.loading = true
      await this.fetchSiteWelcomeMessage(this.siteId)
      if (this.siteWelcomeMessage) {
        this.setupHighlightedResponse()
      }
      this.loading = false
    },
  },
  computed: {
    ...mapState('site', ['siteWelcomeMessage', 'siteWelcomeMessageError']),
  },
  methods: {
    ...mapActions('site', ['fetchSiteWelcomeMessage']),
    getIconForMetric(key, variation) {
      if (variation === 0 || variation === null) {
        return 'IcTrendingNeutral'
      }
      if (['cost', 'CPA', 'CPC'].includes(key)) {
        return variation < 0 ? 'IcTrendingDown' : 'IcTrendingUp'
      }
      return variation > 0 ? 'IcTrendingUp' : 'IcTrendingDown'
    },
    getIconColor(key, variation) {
      if (variation === 0 || variation === null) {
        return 'text-bb-text-secondary'
      }
      if (['cost', 'CPA', 'CPC'].includes(key)) {
        return variation > 0 ? 'text-bb-error' : 'text-success'
      }
      return variation > 0 ? 'text-success' : 'text-bb-error'
    },
    getVarianceColors(key, variation) {
      let colors = 'bg-neutral-50 text-bb-text-secondary'
      if (variation && variation !== 0) {
        if (['cost', 'CPA', 'CPC'].includes(key)) {
          colors = variation > 0 ? 'bg-bb-decorative-red text-bb-error' : 'bg-bb-success-background text-success'
        } else {
          colors = variation > 0 ? 'bg-bb-success-background text-green-700' : 'bg-bb-decorative-red text-bb-error'
        }
      }

      const formattedValue = variation !== null ? (variation > 0 ? `+${variation}%` : `${variation}%`) : 'N/A'

      return { colors, formattedValue }
    },
    setupHighlightedResponse() {
      this.fullHighlightedResponse = this.siteWelcomeMessage?.response
      this.index = 0
      this.displayedHighlightedResponse = ''
      this.typeWriter()
    },
    typeWriter() {
      if (this.index < this.fullHighlightedResponse.length) {
        this.displayedHighlightedResponse += this.fullHighlightedResponse.charAt(this.index)
        this.index++
        setTimeout(this.typeWriter, 20)
      }
    },
  },
  mounted() {
    if (!this.siteId) return
    this.loading = true
    this.fetchSiteWelcomeMessage(this.siteId).then(() => {
      if (this.siteWelcomeMessage) {
        this.setupHighlightedResponse()
      }
      this.loading = false
    })
  },
}
</script>

<style scoped>
.typewriter::after {
  content: '|';
  display: inline-block;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pre-render {
  visibility: hidden;
}
</style>
