<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Eye</title>
    <path
      d="M15.0002 0.333313C8.3335 0.333313 2.64016 4.47998 0.333496 10.3333C2.64016 16.1866 8.3335 20.3333 15.0002 20.3333C21.6668 20.3333 27.3602 16.1866 29.6668 10.3333C27.3602 4.47998 21.6668 0.333313 15.0002 0.333313ZM15.0002 17C11.3202 17 8.3335 14.0133 8.3335 10.3333C8.3335 6.65331 11.3202 3.66665 15.0002 3.66665C18.6802 3.66665 21.6668 6.65331 21.6668 10.3333C21.6668 14.0133 18.6802 17 15.0002 17ZM15.0002 6.33331C12.7868 6.33331 11.0002 8.11998 11.0002 10.3333C11.0002 12.5466 12.7868 14.3333 15.0002 14.3333C17.2135 14.3333 19.0002 12.5466 19.0002 10.3333C19.0002 8.11998 17.2135 6.33331 15.0002 6.33331Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ic-eye',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
