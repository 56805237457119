<template>
  <div class="relative w-full h-32">
    <line-chart
      class="h-32 absolute w-full"
      v-if="actual && forecast"
      :chart-data="parsedChartData"
      :month="month"
      :currency="currency"
    ></line-chart>
  </div>
</template>

<script>
import LineChart from '@/components/chart/bbcharts/base/LineChart.vue'

export default {
  name: 'BbChart',
  components: {
    LineChart,
  },
  props: {
    actual: {
      type: Array,
      required: true,
    },
    forecast: {
      type: Array,
      required: true,
    },
    threshold: {
      type: String,
      default: 'good',
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: false,
    },
  },
  computed: {
    parsedChartData() {
      let borderColor, backgroundColor, backdropColor

      switch (this.threshold) {
        case 'good':
          borderColor = 'rgba(24, 176, 112, 1)'
          backgroundColor = 'rgba(171, 230, 210, 0.5)'
          backdropColor = 'rgba(230, 254, 250, 0.5)'
          break
        case 'bad':
          borderColor = 'rgba(215, 54, 60, 1)'
          backgroundColor = 'rgba(255, 221, 220, 0.5)'
          backdropColor = 'rgba(255, 221, 220, 0.5)'
          break
        case 'ok':
          borderColor = 'rgba(255, 168, 0, 1)'
          backgroundColor = 'rgba(255, 244, 213, 0.5)'
          backdropColor = 'rgba(255, 243, 208, 0.5)'
          break
        default:
          borderColor = 'rgba(24, 176, 112, 1)'
          backgroundColor = 'rgba(171, 230, 210, 0.5)'
          backdropColor = 'rgba(230, 254, 250, 0.5)'
      }

      return {
        labels: Array.from({ length: this.actual.length }, (_, i) => i + 1),
        datasets: [
          {
            label: 'Actual',
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            data: this.actual,
            fill: true,
            pointRadius: 0,
            lineTension: 0.6,
          },
          {
            label: 'Forecast',
            backgroundColor: 'rgba(240, 241, 243, 0.4)',
            borderWidth: 1,
            borderDash: [10, 10],
            borderColor: '#AFAFAF',
            data: this.forecast,
            fill: true,
            pointRadius: 0,
            lineTension: 0.6,
          },
          {
            label: 'Area Above Line',
            fill: true,
            pointRadius: 0,
            backgroundColor: backdropColor,
            borderColor: 'rgba(0, 0, 0, 0)',
          },
        ],
      }
    },
  },
}
</script>
