<template>
  <div
    v-if="nbBudgetSuggestions && !isLoading"
    class="flex gap-x-8 py-4"
    :class="{
      'hidden ': !nbBudgetSuggestions,
    }"
  >
    <!-- Budget Allocator banner  -->
    <ContentCard class="w-full cardBg">
      <template #header>
        <div class="flex justify-between w-full text-normal font-medium">
          <div class="flex gap-x-4">
            <div class="moneyIcon p-2">
              <ic-dollar
                :size="17"
                class="text-bb-brand-purple"
              ></ic-dollar>
            </div>

            <div>
              <span class="text-gray-700 text-base font-medium">You have </span>
              <span class="text-indigo-500 text-base font-bold pNum">{{ nbBudgetSuggestions }}</span>
              <span class="text-gray-700 text-base font-medium"> new budget suggestions!</span>
              <div class="text-gray-700 text-sm font-normal mt-2">
                Effortlessly optimize your budget using our AI-driven suggestions.
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #contents>
        <div class="flex gap-5">
          <div
            v-for="(campaign, index) in campaignsList"
            :key="index"
            class="w-1/2"
          >
            <!-- SUGGESTED BUDGET -->
            <div class="px-5 py-2 w-full dashboardSuggestionCard justify-between items-center inline-flex">
              <div class="w-full">
                <div>
                  <div class="text-gray-700 text-sm font-normal max-w-xs truncate">{{ campaign.name }}</div>
                </div>
                <div class="flex justify-between mt-2">
                  <div class="flex text-gray-700 my-auto">
                    <div class="text-sm font-bold">Budget:&nbsp;</div>
                    <div class="flex">
                      <div class="text-right pNumLarge font-medium">{{ campaign?.budget }}</div>
                      <div class="text-right text-sm font-medium">{{ campaign.currency }}/day</div>
                    </div>
                  </div>
                  <div
                    class="text-sm font-bold flex suggestedBudget justify-around py-2 px-4 text-bb-brand-purple rounded-lg"
                  >
                    <IcStars
                      :size="16"
                      class="text-indigo-500 mr-2 my-auto"
                    />
                    <div class="flex">
                      <div>Suggested Budget:</div>

                      <div class="flex ml-1">
                        <div class="text-right text-neutral-950 pNumLarge font-bold">
                          {{ campaign.suggestedBudget }}
                        </div>
                        <div class="text-right text-neutral-950 text-sm font-medium ml-2">
                          {{ campaign.currency }}/day
                        </div>
                        <!-- arrow with percentage -->
                        <div
                          v-if="campaign.newBudgetRatio"
                          class="ml-2 flex items-center"
                        >
                          <ic-arrow
                            :size="10"
                            class="transform my-auto"
                            :class="{
                              ' text-bb-error ': campaign.newBudgetRatio < 0,
                              'rotate-180 text-success': campaign.newBudgetRatio >= 0,
                            }"
                          ></ic-arrow>
                          <div
                            :class="{
                              'text-red-600': campaign.newBudgetRatio < 0,
                              'text-green-600': campaign.newBudgetRatio >= 0,
                            }"
                            class="text-xs font-medium mt-1 ml-1"
                          >
                            {{ campaign.newBudgetRatio }}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="nbBudgetSuggestions - campaignsList.length > 0"
            class="w-8 h-8 px-1.5 my-auto py-px bg-white rounded-lg justify-center items-center inline-flex"
          >
            <div class="text-indigo-500 text-sm font-bold leading-none tracking-wide">
              {{ nbBudgetSuggestions - campaignsList.length }}
            </div>
          </div>
        </div>
        <div class="mt-3 text-center">
          <MergeButtonRound @click="$router.push({ path: '/console/campaigns?state=managingBudget' })">
            Manage Budget
          </MergeButtonRound>
        </div>
      </template>
    </ContentCard>
  </div>
</template>

<script>
import ContentCard from '@/components/card/ContentCard.vue'
import IcDollar from '@/components/icon/ic-dollar.vue'
import Toast from '@/components/shared/Toast.vue'
import { mapState } from 'vuex'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import IcStars from '@/components/icon/ic-stars.vue'
import IcArrow from '@/components/icon/ic-arrow.vue'

export default {
  name: 'BudgetAllocatorBanner',
  components: {
    ContentCard,
    MergeButtonRound,
    IcDollar,
    IcStars,
    IcArrow,
  },
  props: {},

  data() {
    return {
      isSpendCardExpanded: true,
      nbBudgetSuggestions: 0,
      isLoading: true,
      campaignsList: [],
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
  },
  watch: {
    async selectedSite() {
      Promise.all([this.getBudgetSuggestionsCount(), this.getSiteCampaignsBudgetSuggestions()])
    },
  },
  async mounted() {
    Promise.all([this.getBudgetSuggestionsCount(), this.getSiteCampaignsBudgetSuggestions()])
  },
  methods: {
    async getBudgetSuggestionsCount() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get(`/common/site/${this.selectedSite.value}/budget-suggestion/count`)
        this.nbBudgetSuggestions = data.count
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed get the number of budget suggestions',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getSiteCampaignsBudgetSuggestions() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get(`common/site/${this.selectedSite.value}/budget-suggestion`)

        this.campaignsList = data.campaigns
          .map(campaign => ({
            ...campaign,
            id: campaign.campaign_id,
            name: campaign.campaign_name,
            budget: campaign.budget,
            avgSpend: campaign.metrics_cost,
            suggestedBudget: campaign.suggested_budget,
            currency: data.currency,
            previousBudget: campaign.last_activity?.previous_budget,
            adjustedBudget: campaign.last_activity?.adjusted_budget,
            newBudgetRatio: Math.round(((campaign?.suggested_budget - campaign?.budget) / campaign?.budget) * 100),
          }))
          .filter(campaign => campaign.suggestion_applied === false)
          .splice(0, 2)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch campaigns budget suggestions for the dashboard',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
.moneyIcon {
  border-radius: 8px;
  background: #fff;
  height: 33px;
  box-shadow: 0px 5px 22px 4px rgba(136, 152, 170, 0.12);
}
.cardBg {
  background: #f9fafb;
}
.dashboardSuggestionCard {
  padding: 12px;
  background: white;
  box-shadow: 0px 12px 47px -2px #0000000d;
  border-radius: 8px;
}
.suggestedBudget {
  background: #f9f8ff;
}
</style>
