<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 88 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="44"
      cy="44.5"
      r="36"
      fill="#6366FA"
      fill-opacity="0.1"
    />
    <circle
      cx="43.8076"
      cy="44"
      r="29.5"
      fill="#6366FA"
      fill-opacity="0.1"
    />
    <path
      d="M37.0884 41.5L35.8384 38.75L33.0884 37.5L35.8384 36.25L37.0884 33.5L38.3384 36.25L41.0884 37.5L38.3384 38.75L37.0884 41.5Z"
      fill="#6366FA"
    />
    <path
      d="M37.0884 47.5L38.3384 50.25L41.0884 51.5L38.3384 52.75L37.0884 55.5L35.8384 52.75L33.0884 51.5L35.8384 50.25L37.0884 47.5Z"
      fill="#6366FA"
    />
    <path
      d="M44.5884 42L47.0884 36.5L49.5884 42L55.0884 44.5L49.5884 47L47.0884 52.5L44.5884 47L39.0884 44.5L44.5884 42ZM46.0984 45.49L47.0884 47.67L48.0784 45.49L50.2584 44.5L48.0784 43.51L47.0884 41.33L46.0984 43.51L43.9184 44.5L46.0984 45.49Z"
      fill="#6366FA"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcStarCircleEnabled',
  props: {
    size: {
      type: [String, Number],
      default: 88,
    },
  },
}
</script>
