<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 72 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      opacity="0.5"
      cx="36"
      cy="36.5"
      r="36"
      fill="#F9FAFB"
    />
    <circle
      cx="36.5"
      cy="37"
      r="29.5"
      fill="#F0F1F3"
    />
    <path
      d="M43.4261 40.3679H42.0687L41.5876 39.9048C43.2715 37.9494 44.2852 35.4108 44.2852 32.6492C44.2852 26.4914 39.2852 21.5 33.1168 21.5C27.2406 21.5 22.4296 26.0283 22 31.7916H25.4708C25.9003 27.9322 29.1306 24.9305 33.1168 24.9305C37.3952 24.9305 40.8488 28.3782 40.8488 32.6492C40.8488 36.9202 37.3952 40.3679 33.1168 40.3679C32.8247 40.3679 32.5498 40.3165 32.2577 40.2822V43.747C32.5498 43.7813 32.8247 43.7985 33.1168 43.7985C35.8832 43.7985 38.4261 42.7864 40.3849 41.1055L40.8488 41.5858V42.9408L49.4399 51.5L52 48.9443L43.4261 40.3679Z"
      fill="#6D7883"
    />
    <path
      d="M24.746 38.7985L21.8772 35.9602L20 37.8044L24.746 42.5L34 33.3442L32.136 31.5L24.746 38.7985Z"
      fill="#6D7883"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcSearchPositiveDisabled',
  props: {
    size: {
      type: [String, Number],
      default: 72,
    },
  },
}
</script>
