var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white border border-neutral-100 rounded-lg px-6 py-4 flex flex-col gap-3"},[_c('div',{staticClass:"flex flex-col gap-3"},[_c('div',{staticClass:"flex flex-row gap-2"},[_c('div',{staticClass:"bg-neutral-0 rounded-lg p-1 my-auto"},[_c(_vm.iconComponent,{tag:"component",staticClass:"h-5 w-5 m-1 text-bb-brand-purple"})],1),_c('p',{staticClass:"h5 my-auto capitalize"},[_vm._v(" "+_vm._s(_vm.label)+" ")])]),_c('div',{staticClass:"flex flex-row flex-wrap gap-3"},[_c('p',{staticClass:"pNumMassive"},[_vm._v(" "+_vm._s(_vm.formatNumberWithSpaces(_vm.metric.current) ?? '-')),(_vm.label === 'cost' || _vm.label === 'average CPC')?_c('span',{staticClass:"my-auto ml-4"},[_vm._v(_vm._s(_vm.currency))]):_vm._e(),(_vm.label === 'conversion rate' || _vm.label === 'CTR')?_c('span',{staticClass:"my-auto ml-1"},[_vm._v("%")]):_vm._e()]),_c('div',{staticClass:"flex flex-row gap-1"},[_c('ic-arrow',{staticClass:"transform my-auto",class:{
            '-rotate-90 text-success':
              (_vm.label === 'cost' || _vm.label === 'average CPC') && _vm.metric.variation_percentage <= 0,
            'rotate-90 text-bb-error':
              (_vm.label === 'cost' || _vm.label === 'average CPC') && _vm.metric.variation_percentage > 0,
            'rotate-90 text-success': _vm.label !== 'cost' && _vm.label !== 'average CPC' && _vm.metric.variation_percentage > 0,
            '-rotate-90 text-bb-error':
              _vm.label !== 'cost' && _vm.label !== 'average CPC' && _vm.metric.variation_percentage <= 0,
          },attrs:{"size":12}}),_c('p',{staticClass:"pNumLarge text-base my-auto",class:{
            'text-bb-error':
              _vm.label === 'cost' || _vm.label === 'average CPC'
                ? _vm.metric.variation_percentage > 0
                : _vm.metric.variation_percentage <= 0,
            'text-success':
              _vm.label === 'cost' || _vm.label === 'average CPC'
                ? _vm.metric.variation_percentage <= 0
                : _vm.metric.variation_percentage > 0,
          }},[_vm._v(" "+_vm._s(_vm.metric.variation_percentage > 0 ? '+' : '')+_vm._s(_vm.metric.variation_percentage ?? '-')+"% ")])],1)])]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('p',{staticClass:"text-bb-text-secondary"},[_vm._v(" Previous: "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.metric.compare ? _vm.formatNumberWithSpaces(_vm.metric.compare) : '-')),(_vm.label === 'cost' || _vm.label === 'average CPC')?_c('span',{staticClass:"my-auto ml-1"},[_vm._v(_vm._s(_vm.currency))]):_vm._e(),(_vm.label === 'conversion rate' || _vm.label === 'CTR')?_c('span',{staticClass:"my-auto ml-1"},[_vm._v("%")]):_vm._e()])]),_c('p',{staticClass:"text-bb-text-secondary"},[_vm._v(" Change: "),_c('span',{staticClass:"font-bold pNum",class:{
          'text-bb-error':
            _vm.label === 'cost' || _vm.label === 'average CPC' ? _vm.metric.variation_value >= 0 : _vm.metric.variation_value < 0,
          'text-success':
            _vm.label === 'cost' || _vm.label === 'average CPC' ? _vm.metric.variation_value < 0 : _vm.metric.variation_value >= 0,
        }},[_vm._v(" "+_vm._s(_vm.metric.variation_value > 0 ? '+' : '')+_vm._s(_vm.formatNumberWithSpaces(_vm.metric.variation_value) ?? '-')+" "),(_vm.label === 'cost' || _vm.label === 'average CPC')?_c('span',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.currency))]):_vm._e(),(_vm.label === 'conversion rate' || _vm.label === 'CTR')?_c('span',{staticClass:"my-auto"},[_vm._v("%")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }