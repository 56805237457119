<template>
  <div
    ref="funnelContainer"
    class="w-full"
  >
    <div v-if="!isDataLoading">
      <div class="relative w-full funnels">
        <div class="absolute grid grid-cols-3 items-top pt-2">
          <div class="justify-self-start">
            <div class="h5 text-bb-text-secondary">Impressions</div>
            <div class="flex flex-row flex-wrap gap-3">
              <p class="pNumMassive font-medium">{{ formatNumberWithSpaces(impressions?.current) ?? '-' }}</p>
              <div class="flex flex-row gap-1 items-end">
                <p class="py-1">
                  <ic-arrow
                    :size="16"
                    class="transform my-auto"
                    :class="{
                      'rotate-180 text-success': impressions.variation_percentage > 0,
                      'text-bb-error': impressions.variation_percentage <= 0,
                    }"
                  ></ic-arrow>
                </p>
                <p
                  class="pNumLarge text-base"
                  :class="{
                    'text-success': impressions.variation_percentage > 0,
                    'text-bb-error': impressions.variation_percentage <= 0,
                  }"
                >
                  {{ parsePercentage(impressions?.variation_percentage) }}%
                </p>
                <p class="text-bb-text-secondary">previous</p>
              </div>
            </div>
          </div>
          <div class="justify-self-center ml-4">
            <div class="h5 text-bb-text-secondary">Clicks</div>
            <div class="flex flex-row flex-wrap gap-3">
              <p class="pNumMassive font-medium">{{ formatNumberWithSpaces(clicks?.current) ?? '-' }}</p>
              <div class="flex flex-row gap-1 items-end">
                <p class="py-1">
                  <ic-arrow
                    :size="16"
                    class="transform my-auto"
                    :class="{
                      'rotate-180 text-success': clicks.variation_percentage > 0,
                      'text-bb-error': clicks.variation_percentage <= 0,
                    }"
                  ></ic-arrow>
                </p>
                <p
                  class="pNumLarge text-base"
                  :class="{
                    'text-success': clicks.variation_percentage > 0,
                    'text-bb-error': clicks.variation_percentage <= 0,
                  }"
                >
                  {{ parsePercentage(clicks?.variation_percentage) }}%
                </p>
                <p class="text-bb-text-secondary">previous</p>
              </div>
            </div>
          </div>
          <div class="justify-self-end">
            <div class="h5 text-bb-text-secondary">Conversions</div>
            <div class="flex flex-row flex-wrap gap-3">
              <p class="pNumMassive font-medium">{{ formatNumberWithSpaces(conversions?.current) ?? '-' }}</p>
              <div class="flex flex-row gap-1 items-end">
                <p class="py-1">
                  <ic-arrow
                    :size="16"
                    class="transform my-auto"
                    :class="{
                      'rotate-180 text-success': conversions.variation_percentage > 0,
                      'text-bb-error': conversions.variation_percentage <= 0,
                    }"
                  ></ic-arrow>
                </p>
                <p
                  class="pNumLarge text-base"
                  :class="{
                    'text-success': conversions.variation_percentage > 0,
                    'text-bb-error': conversions.variation_percentage <= 0,
                  }"
                >
                  {{ parsePercentage(conversions?.variation_percentage) }}%
                </p>
                <p class="text-bb-text-secondary">previous</p>
              </div>
            </div>
          </div>
        </div>
        <div class="absolute z-10 grid grid-cols-3">
          <div class="h-full"></div>
          <div class="h-full flex flex-col items-start">
            <div class="w-fit mt-8 mb-2 text-bb-brand-purple text-base">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="36"
                viewBox="0 0 56 36"
                fill="none"
              >
                <path
                  d="M0.724609 4.0885C0.724609 1.87936 2.51547 0.088501 4.72461 0.088501H44.1233C45.6072 0.088501 46.9691 0.909893 47.6613 2.22234L54.7936 15.7441C55.4076 16.9083 55.4097 18.3001 54.7991 19.466L47.6588 33.1002C46.9684 34.4185 45.6034 35.2445 44.1153 35.2445H4.72461C2.51548 35.2445 0.724609 33.4536 0.724609 31.2445V4.0885Z"
                  fill="#F4F2FF"
                />
                <text
                  x="50%"
                  y="50%"
                  fill="#6366FA"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  font-size="15"
                  font-weight="bold"
                >
                  {{ parsePercentage((clicks?.current / impressions?.current) * 100) }}%
                </text>
              </svg>
            </div>
            <div class="h-full ml-6 border-r border-dashed border-gray-500"></div>
          </div>
          <div class="h-full flex flex-col items-start">
            <div class="w-fit mt-8 mb-2 text-bb-brand-purple text-base">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="36"
                viewBox="0 0 56 36"
                fill="none"
              >
                <path
                  d="M0.724609 4.0885C0.724609 1.87936 2.51547 0.088501 4.72461 0.088501H44.1233C45.6072 0.088501 46.9691 0.909893 47.6613 2.22234L54.7936 15.7441C55.4076 16.9083 55.4097 18.3001 54.7991 19.466L47.6588 33.1002C46.9684 34.4185 45.6034 35.2445 44.1153 35.2445H4.72461C2.51548 35.2445 0.724609 33.4536 0.724609 31.2445V4.0885Z"
                  fill="#F4F2FF"
                />
                <text
                  x="50%"
                  y="50%"
                  fill="#6366FA"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  font-size="15"
                  font-weight="bold"
                >
                  {{ parsePercentage((conversions?.current / clicks?.current) * 100) }}%
                </text>
              </svg>
            </div>
            <div class="h-full ml-6 border-r border-dashed border-gray-500"></div>
          </div>
        </div>
        <div class="absolute pt-20">
          <vue-funnel-graph
            class="w-full h-full"
            :width="width"
            :height="height"
            :labels="labels"
            :values="values"
            :colors="colors"
            :direction="direction"
            :gradient-direction="gradientDirection"
            :animated="true"
            :display-percentage="false"
          ></vue-funnel-graph>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="flex flex-row gap-2 items-center">
          <ic-dollar
            :size="18"
            class="text-bb-brand-purple"
          ></ic-dollar>
          <p class="h5 text-bb-text-secondary">Total Cost:</p>
          <p class="bb-text-headers font-bold">
            <span v-if="cost?.current">{{ formatNumberWithSpaces(cost?.current) }} {{ data.currency }}</span>
            <span v-else>-</span>
          </p>
        </div>

        <div class="flex flex-row gap-2 items-center">
          <ic-target-cpa
            :size="20"
            class="text-bb-brand-purple"
          />
          <p class="h5 text-bb-text-secondary">CPA:</p>
          <p class="bb-text-headers font-bold">
            <span v-if="cpa?.current">{{ formatNumberWithSpaces(cpa?.current) }} {{ data.currency }}/Conv</span>
            <span v-else>-</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueFunnelGraph } from 'vue-funnel-graph-js-v2'
import icArrow from '@/components/icon/ic-arrow.vue'
import IcDollar from '@/components/icon/ic-dollar.vue'
import IcTargetCpa from '@/components/icon/ic-target-cpa.vue'

export default {
  name: 'FunnelChart',
  components: {
    VueFunnelGraph,
    icArrow,
    IcDollar,
    IcTargetCpa,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      labels: ['', ' ', '  '],
      values: [1, 1, 1],
      colors: ['#9CF0D5', '#6EA7E3', '#6366FA'],
      direction: 'horizontal',
      gradientDirection: 'horizontal',
      height: null,
      width: null,
      impressions: null,
      clicks: null,
      conversions: null,
      cost: null,
      cpa: null,
      isDataLoading: true,
    }
  },
  watch: {
    data() {
      this.loadData()
    },
  },
  mounted() {
    this.setWidth()
    this.setHeight()
    this.loadData()
  },
  methods: {
    loadData() {
      this.impressions = this.data.impressions
      this.clicks = this.data.clicks
      this.conversions = this.data.conversions
      this.cost = this.data.cost
      this.cpa = this.data.cpa

      this.values = [this.impressions?.current || 1, this.clicks?.current || 1, this.conversions?.current || 1]
      this.isDataLoading = false
    },
    makeVertical() {
      this.direction = 'vertical'
      this.height = 500
      this.width = 400
      this.gradientV()
    },
    makeHorizontal() {
      this.direction = 'horizontal'
      this.height = 300
      this.width = 800
      this.gradientH()
    },
    toggleDirection() {
      this.direction === 'horizontal' ? this.makeVertical() : this.makeHorizontal()
    },
    gradientV() {
      this.gradientDirection = 'vertical'
    },
    gradientH() {
      this.gradientDirection = 'horizontal'
    },
    toggleGradient() {
      this.gradientDirection === 'horizontal' ? this.gradientV() : this.gradientH()
    },
    setHeight() {
      const containerHeight = this.$refs.funnelContainer ? this.$refs.funnelContainer.offsetHeight : 250
      this.height = this.direction === 'horizontal' ? 150 : containerHeight
    },
    setWidth() {
      const containerwidth = this.$refs.funnelContainer ? this.$refs.funnelContainer.offsetWidth : 800
      this.width = this.direction === 'horizontal' ? containerwidth : 400
    },
    parsePercentage(num) {
      return num && !isNaN(parseFloat(num)) ? parseFloat(num).toFixed(2) : '-'
    },
    parseAndRemoveZeroes(num) {
      return num ? parseFloat(parseFloat(num).toFixed(2)) : 0
    },
    formatNumberWithSpaces(num) {
      return this.parseAndRemoveZeroes(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
}
</script>

<style>
.funnels {
  height: 250px;
}

.funnels > div,
.funnels > div > svg,
.funnels svg {
  width: 100%;
  height: 100%;
}

.funnel:not(.svg-funnel-js--vertical) {
  transition: transform 0.3s ease;
  padding: 0% 0% 0% 0%;
}

.svg-funnel-js__labels {
  display: none !important;
}
</style>
