<template>
  <div class="space-y-3 py-2">
    <div class="py-6 px-10 statsCard">
      <div class="md:flex justify-around">
        <div
          v-if="!autopilot.autopilotStatus && autopilot.actions.length === 0 && !autopilot.keywordSuggestionsEnabled"
          class="my-auto text-center p-2"
        >
          <p class="pb-4">
            Keyword Suggestions have been disabled for this Campaign. To access insights, please enable Keyword
            Suggestion first.
          </p>
          <merge-button-round
            button-type="primary"
            @click="$router.push({ path: '/console/site/search/keyword-suggestions' })"
          >
            Go to Keyword Settings
          </merge-button-round>
        </div>
        <div
          v-else
          class="w-full grid grid-cols-3 divide-x gap-x-auto"
        >
          <div class="content-center col-span-2">
            <div
              v-if="autopilot.autopilotStatus"
              class="my-auto"
            >
              The Autopilot has added the following keywords:
            </div>
            <div
              v-if="!autopilot.autopilotStatus && autopilot.actions.length > 0"
              class="my-auto"
            >
              Autopilot is disabled on all your campaigns. Please
              <span class="text-bb-brand-purple">enable Autopilot</span>
              to get insights.
            </div>
            <div
              v-if="!autopilot.autopilotStatus && autopilot.actions.length === 0"
              class="my-auto"
            >
              Total of <span class="font-bold">keywords</span> suggested
            </div>
            <div class="flex flex-wrap gap-x-12 p-4">
              <div class="flex">
                <div class="my-auto">
                  <IcStarCircleDisabled
                    v-if="!autopilot.autopilotStatus && autopilot.actions.length > 0"
                    size="36"
                  ></IcStarCircleDisabled>
                  <IcStarCircleEnabled
                    v-else
                    size="36"
                  ></IcStarCircleEnabled>
                </div>
                <div class="ml-2 my-auto">
                  <div
                    class="text-2xl leading-6 pNum bb-text-headers font-semibold"
                    :class="{ 'text-bb-text-secondary': !autopilot.autopilotStatus && autopilot.actions.length > 0 }"
                  >
                    {{ formatNumberWithSpaces(autopilot.summary.total) }}
                  </div>
                  <div class="text-bb-text-secondary">Total</div>
                </div>
              </div>
              <div class="flex">
                <div class="my-auto">
                  <IcSearchPositiveDisabled
                    v-if="!autopilot.autopilotStatus && autopilot.actions.length > 0"
                    size="36"
                  ></IcSearchPositiveDisabled>
                  <IcSearchPositiveEnabled
                    v-else
                    size="36"
                  ></IcSearchPositiveEnabled>
                </div>
                <div class="ml-2 my-auto">
                  <div
                    class="text-2xl leading-6 pNum bb-text-headers font-semibold"
                    :class="{ 'text-bb-text-secondary': !autopilot.autopilotStatus && autopilot.actions.length > 0 }"
                  >
                    {{ formatNumberWithSpaces(autopilot.summary.positive) }}
                  </div>
                  <div class="text-xs mt-1 text-bb-text-secondary">Positive keywords</div>
                </div>
              </div>
              <div class="flex">
                <div class="my-auto">
                  <IcSearchNegativeDisabled
                    v-if="!autopilot.autopilotStatus && autopilot.actions.length > 0"
                    size="36"
                  ></IcSearchNegativeDisabled>
                  <IcSearchNegativeEnabled
                    v-else
                    size="36"
                  ></IcSearchNegativeEnabled>
                </div>
                <div class="ml-2 my-auto">
                  <div
                    class="text-2xl leading-6 pNum bb-text-headers font-semibold"
                    :class="{ 'text-bb-text-secondary': !autopilot.autopilotStatus && autopilot.actions.length > 0 }"
                  >
                    {{ formatNumberWithSpaces(autopilot.summary.negative) }}
                  </div>
                  <div class="text-xs mt-1 text-bb-text-secondary">Negative keywords</div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center content-center col-span-1">
            <div v-if="!autopilot.autopilotStatus && autopilot.actions.length === 0">
              <p class="pb-4">Get AI-driven automation by activating Autopilot</p>
              <autopilot-button
                :enabled="autopilot.autopilotStatus"
                @click="$router.push({ path: '/console/site/search/keyword-suggestions' })"
              />
            </div>
            <div v-else>
              <p>Total of keyword suggested</p>
              <div class="justify-center flex flex-row p-4">
                <div class="my-auto">
                  <IcKeysStar size="36"></IcKeysStar>
                </div>
                <div class="ml-2 my-auto text-left">
                  <div class="text-2xl leading-6 pNum bb-text-headers font-semibold">
                    {{ formatNumberWithSpaces(autopilot.summary.totalSuggestedKeywords) }}
                  </div>
                  <div class="text-xs mt-1 text-bb-text-secondary">Keywords suggested</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcStarCircleDisabled from '@/components/icon/ic-star-circle-disabled.vue'
import IcStarCircleEnabled from '@/components/icon/ic-star-circle-enabled.vue'
import IcSearchPositiveDisabled from '@/components/icon/ic-search-positive-disabled.vue'
import IcSearchNegativeDisabled from '@/components/icon/ic-search-negative-disabled.vue'
import IcSearchPositiveEnabled from '@/components/icon/ic-search-positive-enabled.vue'
import IcSearchNegativeEnabled from '@/components/icon/ic-search-negative-enabled.vue'
import AutopilotButton from '@/components/btn/AutopilotButton.vue'
import IcKeysStar from '@/components/icon/ic-keys-star.vue'

export default {
  name: 'AutopilotCard',
  components: {
    IcStarCircleDisabled,
    IcStarCircleEnabled,
    IcSearchPositiveDisabled,
    IcSearchNegativeDisabled,
    IcSearchPositiveEnabled,
    IcSearchNegativeEnabled,
    AutopilotButton,
    IcKeysStar,
  },
  props: {
    autopilot: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatNumberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
}
</script>

<style scoped>
.statsCard {
  box-shadow: 0px 12px 47px -2px #0000000d;
  border-radius: 16px;
}
</style>
