<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Clicks</title>
    <path
      d="M21.0727 17.2648L17.6146 17.6627L19.9061 21.551C20.0656 21.8205 19.9574 22.1557 19.6815 22.3096L18.1531 23.1396C17.8683 23.2946 17.5174 23.1954 17.3589 22.9345L15.1817 19.2424L12.6658 22.2534C12.3305 22.6546 11.6882 22.4489 11.6342 21.9796L10.1443 9.03253C10.0874 8.53836 10.7091 8.22273 11.0866 8.53157L21.3721 16.2795C21.7849 16.5733 21.5781 17.2066 21.0727 17.2648Z"
      fill="currentColor"
    />
    <path
      d="M8.71399 3.9704L7.07527 1.24122L5.4125 2.1643L7.05122 4.89348L8.71399 3.9704Z"
      fill="currentColor"
    />
    <path
      d="M5.66206 7.35408L2.49638 6.53846L2 8.32224L5.1652 9.13706L5.66206 7.35408Z"
      fill="currentColor"
    />
    <path
      d="M13.479 4.52167L14.3273 1.47775L12.473 1L11.6252 4.04472L13.479 4.52167Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-clicks',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
